
document.addEventListener('DOMContentLoaded', () => {
  setTimeout(overlayOpen, 2000);
  const sendingForm = document.querySelectorAll('.submit-form');
  
  sendingForm.forEach(item => {
      item.addEventListener('submit', formSend);
    }
  );
});
const thanksPopup = document.querySelector('.thanks-popup');
const validateRegNumber = /^(\+7|7|8) ?\(?[0-9]{3}\)?[-| ]?[0-9]{3}[-| ]?[0-9]{2}[-| ]?[0-9]{2}$/;
async function formSend(e) {
  e.preventDefault();

  let error = formValidate(e.target);
  let formData = new FormData(e.target);

  if(error === 0) {
    e.target.classList.add('_sending');

    let response = await fetch('sendtelegram.php', {
      method: 'POST',
      body: formData,
    });
    if(response.ok) {
      let result = await response.json();
      thanksPopup.classList.add('active');
      e.target.reset();
      thanksPopup.classList.add('active');
      const thanksPopupClose = document.querySelector('.thanks-popup__close');
      thanksPopupClose.addEventListener('click', () => {
        thanksPopup.classList.remove('active');
      });
      e.target.classList.remove('_sending');
    } else {
      const thanksPopupClose = document.querySelector('.thanks-popup__close');
      thanksPopupClose.addEventListener('click', () => {
        thanksPopup.classList.remove('active');
      });
      // e.target.classList.remove('_sending');
    }

  } else {
    // alert('Заполните обязательные поля');
  }
}
const formValidate = (form) => {
  let error = 0;
  const sendingFormReq = form.querySelectorAll('._req');

  sendingFormReq.forEach((item, i) => {
    const input = item;

    formRemoveError(input);

    if(input.closest('._number')) {
      const numberCheck = validateRegNumber.test(input.value);
      if (!numberCheck) {
        formAddError(input);
        error++;
      }
    } else if(input.value === '') {
      formAddError(input);
      error++;
        
    } else if (input.value.split('').length > 30) {
      let iValue = input.value.split('');
      formAddError(input);
      error++;
    }
  });
  return error;
}
const formAddError = (input) => {
  input.parentElement.classList.add('_error');
  input.classList.add('_error');
}
const formRemoveError = (input) => {
  input.parentElement.classList.remove('_error');
  input.classList.remove('_error');
}

function overlayOpen() {
  const overlay = document.querySelector('.overlay');
  const overlayClose = document.querySelector('.overlay__close');
  const overlayBlock = document.querySelector('.overlay__apps-block');
  overlay.classList.add('_active');
  overlayBlock.classList.add('_active');
  overlayClose.addEventListener('click', () => {
    overlay.classList.remove('_active');
    overlayBlock.classList.add('_active');
  });
}


// Раскрытие бургер меню
const body = document.querySelector('body');
const burger = document.querySelector('.burger');
const nav = document.querySelector('.nav');
burger.addEventListener('click', event => {
  if(burger.classList.contains('_active')) {
    burger.classList.remove('_active');
    nav.classList.remove('_active');
    body.setAttribute('style', '');
  } else if (!burger.classList.contains('_active')) {
    burger.classList.add('_active');
    nav.classList.add('_active');
    body.style.overflow = 'hidden';
    body.style.userSelect = 'none';
    body.addEventListener('click', event => {
      if(!event.target.closest('.nav') & !event.target.closest('.burger') & !event.target.closest('.whatsapp-popup')) {
        burger.classList.remove('_active');
        nav.classList.remove('_active');
      }
    });
  }
});
nav.addEventListener('click', event => {
  if(event.target.closest('.nav__link')) {
    burger.classList.remove('_active');
    nav.classList.remove('_active');
    body.setAttribute('style', '');
  }
});
// Попап с информацией в контактах
const submitFormLink = document.querySelectorAll('.submit-form__policy-text_underline');
const confiditional = document.querySelector('.confiditional');
// Функция, которая отвечает за плавное появление/пропадание попапа
function confiditionalClose(element, state) {
  element.style.opacity = 0;
  body.style.overflow = state;
  setTimeout(() => {
    element.style.display = 'none';
    element.style.visibility = 'hidden';
  }, 300);
}
function confiditionalOpen(element) {
  element.style.display = 'block';
  element.style.visibility = 'visible';
  body.style.overflow = 'hidden';
  setTimeout(() => {
    element.style.opacity = 1;
  }, 100);
}
function confFunc(event, state) {
  console.log(state);
  if (event.target.classList.contains('confiditional')) {
   confiditionalClose(confiditional, state);
  } else if (event.target.closest('.confiditional__close')) {
    confiditionalClose(confiditional, state);  
  }
}
// Отслеживаем клик на ссылку о сборе данных
submitFormLink.forEach((item) => {
  item.addEventListener('click', (event) => {
      if(event.target.closest('.submit-form__policy-text_underline')) {
        event.preventDefault();
        let state;
        if(body.style.overflow === 'hidden') {
          state = 'hidden';
        } else if (body.style.overflow === 'visible') {
          state = 'visible';
        } else {
          state = 'visible';
        }
        confiditional.addEventListener('click', e => {
          confFunc(e, state);
        });
        confiditionalOpen(confiditional);
      }
    })
  }
);

// Логика раскрытия информации в "Информация о доставке"
const deliveryInfoItems = document.querySelector('.delivery-info__items');
deliveryInfoItems.addEventListener( 'click', (e) => {
  const parent = e.target.closest('.delivery-info__item');
  const deliveryInfoDropdown = parent.querySelector('.delivery-info__item-dropdown');
  const deliveryInfoIcon = parent.querySelector('.delivery-info__item-icon');
  const infoItem = deliveryInfoItems.querySelectorAll('.delivery-info__item');
  
  if(e.target.closest('.delivery-info__item')) {
    if(deliveryInfoDropdown.closest('._active')) {
      deliveryInfoIcon.setAttribute('style', '')
      deliveryInfoDropdown.classList.remove('_active');
    } else {
      infoItem.forEach(e => {
        const child = e.querySelector('.delivery-info__item-dropdown');
        const icon = e.querySelector('.delivery-info__item-icon');
        child.classList.remove('_active');
        icon.setAttribute('style', '')
      });
      deliveryInfoDropdown.classList.add('_active');
      deliveryInfoIcon.style.transform = 'rotate(180deg)';
    }
  }
});
// Прокрутка страницы с первоначального экрана при нажатии на кнопки
const headerBottomBtn = document.querySelector('.header-bottom__btn');
const navMenu = document.querySelector('.nav__menu');
const deliveryInfo = document.querySelector('.delivery-info');
navMenu.addEventListener('click', e => {
  if(e.target.closest('.nav__link')) {
    e.preventDefault();
    let elemCoor = String(e.target.href.split('#').slice(1, 2));
    let elem = document.querySelector('#' + elemCoor);
    elem.scrollIntoView({ 
      block: 'nearest', // к ближайшей границе экрана
      behavior: 'smooth', // и плавно 
    });
  }
});
// При клике на кнопку
headerBottomBtn.addEventListener('click', e => {
  // Прокрутим страницу к форме 
  deliveryInfo.scrollIntoView({ 
    block: 'center', // к ближайшей границе экрана
    behavior: 'smooth', // и плавно 
  });
});

// Выпадающая форма обратной связи 
const headerSendBtn = document.querySelector('.header-main__btn');
const header = document.querySelector('.header');
const headerSubmitForm = document.querySelector('.header__submit-form');
const headerFormWrapper = document.querySelector('.header__submit-form-wrapper');
headerSendBtn.addEventListener('click', (e) => {
  headerSubmitForm.classList.add('active');
  headerFormWrapper.classList.add('active');
  body.style.overflow = 'hidden';
  body.style.userSelect = 'none';

  headerFormWrapper.addEventListener('click', (e) => {
    if(!e.target.closest('.header__submit-form') || e.target.closest('.submit-form__close-btn')) {
      headerSubmitForm.classList.remove('active');
      headerFormWrapper.classList.remove('active');
      body.setAttribute('style', '');
    }
  });
});

// Появление popup whatsapp
const whatsappPopup = document.querySelector('.whatsapp-popup');
const whatsappOpen = document.querySelectorAll('#whatsappOpen');
const whatsappClose = whatsappPopup.querySelector('.whatsapp-popup__close');
const whatsappBlock = whatsappPopup.querySelector('.whatsapp-popup__block');
whatsappPopup.addEventListener('click', event => {
  if(!event.target.closest('.whatsapp-popup__block')) {
    whatsappPopup.classList.remove('_active');
    whatsappBlock.classList.remove('_active');
    body.setAttribute('style', '');   
  } else if (event.target.closest('.whatsapp-popup__close')) {
    whatsappPopup.classList.remove('_active');
    whatsappBlock.classList.remove('_active');
    body.setAttribute('style', '');
  }
});
whatsappOpen.forEach(element => {
  element.addEventListener('click', event => {
    event.preventDefault();
    whatsappPopup.classList.add('_active');
    whatsappBlock.classList.add('_active');
    body.style.overflow = 'hidden';
    body.style.userSelect = 'none';
    // body.style.paddingRight = '17px';
    // body.style.background = '#3EAFC7';
  });
})

